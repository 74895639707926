import { useState, useRef } from "react";
import ReactLoading from "react-loading";
import axios from 'axios';

const FileUploader = () => {
    const [files, setFiles] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // New loading state
    const inputRef = useRef();

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setFiles(event.dataTransfer.files[0])
    };

    //  onDownloadProgress: (ProgressEvent) => { console.log(ProgressEvent.progress * 100) }, 

    const handleUpload = () => {
        if (files) {

            // Check if the uploaded file is a CSV
            if (files.type !== 'text/csv') {
                alert('Please upload a CSV file.');
                return; // Prevent further execution
            }

            setIsLoading(true); // Set loading to true when upload starts
            const formData = new FormData();
            formData.append('file', files);
            console.log(formData)

            axios.post('https://api.lakeside-it.ch/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Make sure to set the correct content type
                }
            })
                .then((response) => {
                    // Handle success response from the backend (if needed)
                    console.log('File uploaded successfully!', response.data);
                    const successfulList = response.data.successfulInvoice.join('\n');
                    const failedList = response.data.failedInvoice.join('\n');
                    const notFoundList = response.data.notFound.join('\n');
                    const alertText = `Invoices were generated successfully for the following companies:\n${successfulList}\n\nInvoices failed to generate for the following companies:\n${failedList}\n\nFollowing companies could not be found:\n${notFoundList}`;
                    alert(alertText)
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        const errorMessage = error.response.data.message;
                        alert(`Error: ${errorMessage}`);
                    } else {
                        console.error('Error uploading file:', error);
                        alert('Something went wrong while generating invoices!');
                    }
                }).finally(() => {
                    setIsLoading(false);// Set loading to false when upload completes (success or error)
                    setFiles(null);
                });
        } else {
            console.warn('No file selected for upload.');
        }
    };

    if (isLoading) {
        return (
            <div className="loading-spinner">
                <h1>The csv file is being processed</h1>
                <ReactLoading
                    type="spinningBubbles"
                    color="#0000FF"
                    height={100}
                    width={50}
                />
            </div>
        );
    }

    if (files) return (
        <div className="uploads">
            {/* <ul>
                {Array.from(files).map((file, idx) => <li key={idx}>{file.name}</li>)}
            </ul> */}
            <h2>{files.name}</h2>
            <div className="actions">
                <button onClick={() => setFiles(null)}>Cancel</button>
                <button onClick={handleUpload}>Upload</button>
            </div>
        </div>
    )

    return (
        <>
            <div
                className="dropzone"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <h1>Drag and Drop Files to Upload</h1>
                <h1>Or</h1>
                <input
                    type="file"
                    onChange={(event) => setFiles(event.target.files[0])}
                    hidden
                    accept=".csv"
                    ref={inputRef}
                />
                <button onClick={() => inputRef.current.click()}>Select Files</button>
            </div>
        </>
    );
};

export default FileUploader;
