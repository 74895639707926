import './App.css';
import React, { useState } from 'react';
import FileUploader from './components/FileUploader';
import Login from './components/Login';

const App = () => {
  // State to track user authentication
  const [authenticated, setAuthenticated] = useState(false);

  // Function to handle successful login
  const handleLogin = () => {
    setAuthenticated(true);
  };

  return (
    <div className="container">
      {authenticated ? (
        <FileUploader />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
